<template>
  <v-card>
    <v-card-title class="text-h3 font-weight-bold">
      <slot name="title" />
    </v-card-title>
    <v-card-text>
      <slot name="text" />
      <slot name="button" />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    methods: {
      save () {
        this.$emit('saveFunc')
      },
      close () {
        this.$emit('closeFunc')
      },
    },
  }
</script>
